import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetRILSettlement } from '../../Service/RILSettlemantService';
import { GetAllCompanies } from '../../Service/CompanyService';
import jsPDF from 'jspdf';


const RILSettlementTable = () => {
    const [record, setRecord] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);


    useEffect(() => {
        async function fetchRecord() {
            try {
                const response = await GetRILSettlement();
                if (response && response.data && Array.isArray(response.data)) {
                    setRecord(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchRecord();
    }, []);

    const formatDateString = (isoDateString) => {
        const isoDate = new Date(isoDateString);
        return isoDate.toLocaleString(); 
    };

    const filterRecord = record.filter((reco) =>
    (reco.lcReferenceNo?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.billRef?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.loanRef?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.company?.toLowerCase().includes(searchQuery.toLowerCase()) || '')

);

useEffect(() => {
    async function fetchCompanies() {
        try {
            const response = await GetAllCompanies();
            if (response && response.data && Array.isArray(response.data)) {
                setCompanyData(response.data);
                console.log(response);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    fetchCompanies();
}, []);


const exportToPdf = (reco) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("RIL / STL Advice", 10, 10);
    // const formattedTotalCharge = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(reco.totalCharge);
    // const formattedEquivalentInLkr = new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'INR' }).format(reco.equivalentInLkr);

    doc.setFontSize(12);
    doc.text(`LC Reference No: ${reco.lcReferenceNo}`, 10, 20);
    doc.text(`DC Date: ${formatDateString(reco.rilDate)}`, 10, 30);
    doc.text(`RIL/STL Typ: ${reco.rsttype}`, 10, 40);
    doc.text(`Bill Ref: ${reco.billRef}`, 10, 50);
    doc.text(`Loan Ref: ${reco.loanRef}`, 10, 60);
    doc.text(`Loan Granted Amount: ${reco.loanGrantedAmount}`, 10, 70);
    doc.text(`Loan Balance After Recovery ${reco.loanBalanceAfterRecovery}`, 10, 80);
    doc.text(`Maturity Date: ${formatDateString(reco.maturityDate)}`, 10, 90);
    doc.text(`Loan Interest Rate:${reco.loanInterestRate} `, 10, 100);
    doc.text(`Loan Overdue Rate (% p.a): ${reco.loanOverdueRate}`, 10, 110);
    doc.text(`From 1 (Date): ${formatDateString(reco.from1)}`, 10, 120);
    doc.text(`To 1 (Date): ${formatDateString(reco.to1)}`, 10, 140);
    doc.text(`Days 1: ${reco.days1}`, 10, 150);
    doc.text(`Rate 1: ${reco.rate1}`, 10, 160);
    doc.setFont("helvetica", "bold");
    doc.text(`Value 1: ${reco.value1}`, 10, 170);
    doc.setFont("helvetica", "normal");

    doc.text(`From 2 (Date): ${formatDateString(reco.from2)}`, 10, 180);
    doc.text(`To 2 (Date): ${formatDateString(reco.to2)}`, 10, 190);
    doc.text(`Days 2: ${reco.days2}`, 10, 200);
    doc.text(`Rate 2: ${reco.rate2}`, 10, 210);
    doc.setFont("helvetica", "bold");
    doc.text(`Value 2: ${reco.value2}`, 10, 220);
    doc.setFont("helvetica", "normal");

    doc.text(`Recovery Value Date : ${formatDateString(reco.recoveryValueDate)}`, 10, 240);
    doc.text(`Recovered Capital Amount : ${reco.recoveredCapitalAmount}`, 10, 250);
    doc.setFont("helvetica", "bold");
    doc.text(`Recovered Interest Amount (LKR) : ${reco.recoveredInterestAmount}`, 10, 260);
    doc.text(`Total Amount (LKR) : ${reco.totalAmount}`, 10, 270);
    doc.setFont("helvetica", "normal");

    doc.text(`Company Name: ${reco.company}`,10,280)

    doc.save(`${reco.lcReferenceNo}.pdf`);
};

    return (
        <div>
            <h1>RIL / STL Advice Table</h1>
            <div className="d-flex justify-content-end mt-3">
                <input type='text' placeholder='Search' className='form-control w-25 m-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                <select value={searchQuery} class="form-control w-25 m-1" aria-label="Disabled select example" onChange={(e) => setSearchQuery(e.target.value)} >
                    <option value='' selected>All</option>
                    {companyData.map((recordmap)=>(
                    <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
                    ))}
                </select>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>LC Reference No</th>
                        <th>Company</th>
                        <th>Bill Ref</th>
                        <th>RIL Date</th>
                        <th>Loan Ref</th>
                        <th>Loan Granted Amount</th>
                        <th>Recovery Value Date</th>
                        <th>Recovered Capital Amount (LKR)</th>
                        <th>Recovered Interest Amount (LKR)</th>
                        <th> Total Amount (LKR) </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filterRecord.length > 0 ? (
                        filterRecord.map((reco) => (
                            <tr key={reco.lcReferenceNo}>
                                <td>{reco.lcReferenceNo}</td>
                                <td>{reco.company}</td>
                                <td>{reco.billRef}</td>
                                <td>{formatDateString(reco.rilDate)}</td> 
                                <td>{reco.loanRef}</td>
                                <td className="text-end">{reco.loanGrantedAmount}</td>
                                <td>{formatDateString(reco.recoveryValueDate)}</td> 
                                <td className="text-end">{reco.recoveredCapitalAmount}</td>
                                <td className="text-end">{reco.recoveredInterestAmount}</td>
                                <td className="text-end">{reco.totalAmount}</td>
                                <td>
                                    <button className='btn btn-success'><Link className="text-decoration-none text-white" to={`/Ril-settlement/${reco.lcReferenceNo}`}>Update</Link></button>
                                    <button className='btn btn-info m-1' onClick={() => exportToPdf(reco)}>Export</button>
                                    
                                    </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No matching record found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default RILSettlementTable;
