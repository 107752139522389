import axios from "axios"
import { toast } from "react-toastify";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


export const CreateLCOenningAdvice=async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/v1`,formData);
        if(responce==="OK"){
            toast.success("Added")
        }
        return responce
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetLCOenningAdvice =async()=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/v1`);
        return responce;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetByIdLCOenningAdvice =async(refno)=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/v1/${refno}`);
        return responce.data;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const updateLCOenningAdvice = async(formData)=>{
    console.log(formData)
    try{
        const responce = await axios.patch(`${BACKEND_URL}/api/v1`,formData)
        console.log(responce.data)
        return responce.data


    }catch(error){
        console.log(error)
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const CheckRefNo = async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/v1/check/${formData}`,)
        return responce.data


    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}