import axios from "axios"
import { toast } from "react-toastify";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const CreateLCAmendment=async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/amendment`,formData);
        if(responce==="OK"){
            toast.success("Added")
        }
        return responce
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetLCAmendment =async()=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/amendment`);
        return responce;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetByIdLCAmendment =async(amdno,lcRefNo)=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/amendment/${amdno}/${lcRefNo}`);
        return responce.data;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const updateLCAmendment = async(formData)=>{
    try{
        const responce = await axios.patch(`${BACKEND_URL}/api/amendment`,formData)
        return responce.data


    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const CheckAmdNo = async(lcReferenceNo,amendmentNo)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/amendment/check/${lcReferenceNo}/${amendmentNo}`)
        return responce.data


    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}