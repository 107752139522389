import axios from "axios"
import { toast } from "react-toastify";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const Signup=async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/user`,formData);
        console.log(formData);
        if(responce==="OK"){
            toast.success("Login")
            
        }

        return responce.data
        
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}



export const Register=async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/user/register`,formData);
        console.log(formData);
        if(responce==="OK"){
            toast.success("Register")
            
        }

        return responce.data
        
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}