import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetByIdSettlement, updateSettlement } from '../../Service/LCSettlementService';

const LCSettlementupdate = () => {
    const { amdno } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [record, setRecord] = useState([]);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
        lcReferenceNo:'',
        settlementDate:'',
        billRef:'',
        billAmount:'',
        rate:'',
        settlementAmount:0,
        acceptanceCommission:'',
        telexCharge:'',
        postageCharge:'',
        total:0
    });

    const {
        lcReferenceNo,
        settlementDate,
        billRef,
        billAmount,
        rate,
        settlementAmount,
        acceptanceCommission,
        telexCharge,
        postageCharge,
        total,
} =formData
        
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdSettlement(amdno);
                setRecordData(data);
                setFormData(data);
                console.log(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
        e.preventDefault();
        try {
            await updateSettlement(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-Document/${formData.lcReferenceNo}`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const calculateEquivalentInLKR = () => {
      const calEquivalentInLkr = (parseFloat(formData.billAmount) * parseFloat(formData.rate));
      console.log(calEquivalentInLkr);
      setFormData(prev => ({ ...prev, acceptanceCommission:calEquivalentInLkr}));
    };
  
    const calculateTotCharge = () => {
      const total = parseFloat(formData.acceptanceCommission) + parseFloat(formData.telexCharge) + parseFloat(formData.postageCharge);
      setFormData(prevState => ({ ...prevState, total}));
    };


    const calculateBtn = async() => {
     await calculateEquivalentInLKR();
     await calculateTotCharge();
      
      const isEmptyField = Object.values(formData).some(value => value === '');
      if (isEmptyField) {
          Swal.fire({
              icon: 'warning',
              title: 'Warning!',
              text: 'Please fill all the input fields before calculating.',
              confirmButtonText: 'OK',
          });
          return;
      }
      setIsFormFilled(true)
    };


    const clearForm = () => {
      setFormData('');
      setIsFormFilled(false);
      };




  return (
    <div>
      <h1>LC Settlement Advice Update</h1>

      <form className='pt-4 form-group' onSubmit={updateFunc}>

      <div className='form-group row p-2'>
      <label className='col-sm-4 col-form-label'>Company</label>
        <div className='col-sm-7'>
        <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
            <option selected>Select</option>
              <option value={formData.company}>{formData.company}</option>
          </select>
        </div>
      </div>

      <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Settlement Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="settlementDate" value={formData.settlementDate ? new Date(formData.settlementDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="billRef" value={formData.billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Amount (USD)  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="billAmount" value={formData.billAmount} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Rate  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="rate" value={formData.rate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Settlement Amount (LKR) *  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="settlementAmount" value={formData.settlementAmount} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Handling Charge  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="acceptanceCommission" value={parseFloat(formData.acceptanceCommission).toFixed(2)} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

       

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Telex Charge  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="telexCharge" value={formData.telexCharge} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Postage  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="postageCharge" value={formData.postageCharge} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Toatal </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="total" value={parseFloat(formData.total).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>

        
        
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Settlement-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCSettlementupdate