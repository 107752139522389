import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetByIdShipping, updateShipping } from '../../Service/LCShippingService';


const LCShippingupdate = () => {
    const { amdno } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [record, setRecord] = useState([]);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
        lcReferenceNo:'',
        sgDate:'',
        billRef:'',
        loanRef:'',
        typeofLoan:'',
        loanlAmount:'',
        loanPeriod:'',
        loanRatePerAnnum:'',
        penalIntRate:'',
        loanValueDate:'',
        maturityDate:'',
    });

    const {
        lcReferenceNo,
        sgDate,
        billRef,
        loanRef,
        typeofLoan,
        loanlAmount,
        loanPeriod,
        loanRatePerAnnum,
        penalIntRate,
        loanValueDate,
        maturityDate,
} =formData
        
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdShipping(amdno);
                setRecordData(data);
                setFormData(data);
                console.log(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
        e.preventDefault();
        try {
            await updateShipping(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-shipping`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };




    const clearForm = () => {
      setFormData({
        lcReferenceNo:'',
        sgDate:'',
        billRef:'',
        loanRef:'',
        typeofLoan:'',
        loanlAmount:'',
        loanPeriod:'',
        loanRatePerAnnum:'',
        penalIntRate:'',
        loanValueDate:'',
        maturityDate:'',
      });
      setIsFormFilled(false);
    };




  return (
    <div>
      <h1>Shipping Guaranty Advice Update</h1>

      <form className='pt-4 form-group' onSubmit={updateFunc}>

      <div className='form-group row p-2'>
      <label className='col-sm-4 col-form-label'>Company</label>
        <div className='col-sm-7'>
        <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
            <option selected>Select</option>
              <option value={formData.company}>{formData.company}</option>
          </select>
        </div>
      </div>


      <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>SG Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="sgDate" value={formData.sgDate ? new Date(formData.sgDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="billRef" value={billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Ref  </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="loanRef" value={formData.loanRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Type of Loan   </label>
          <div className='col-sm-7'>
          <select className="form-select" name='typeofLoan'  value={formData.typeofLoan} onChange={handleInputChange} required readOnly={isFormFilled}>
            <option selected>Choose one</option>
            <option value="saab">Long Tearm</option>
            <option value="opel">Short Tearm</option>
            </select>   
            </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Amount (LKR)</label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanlAmount" value={formData.loanlAmount} onChange={handleInputChange} required />
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Period (Days) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanPeriod" value={formData.loanPeriod} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

       

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Loan Rate Per Annum (%) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanRatePerAnnum" value={formData.loanRatePerAnnum} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Penal Int Rate   </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="penalIntRate" value={formData.penalIntRate} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Value Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="loanValueDate" value={formData.loanValueDate ? new Date(formData.loanValueDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} min={0} required />
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Maturity Date </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="maturityDate" value={formData.maturityDate ? new Date(formData.maturityDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} min={0} required />
          </div>
        </div>

        
        
        <div>
          <button type='submit' className='btn btn-success m-2'>Update</button>

          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Shipping-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCShippingupdate