import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { CheckAmdNo, CreateDocument } from '../../Service/LCDdocumentService';
import { GetAllCompanies } from '../../Service/CompanyService';

const LCDocument = () => {
  const [companyData, setCompanyData] = useState([]);
  const [formData, setFormData] =useState(
    {
        lcReferenceNo:'',
        dcDate :'',
        billRef:'',
        billAmount:'',
        billMaturityDate:'',
        commissionRate:'',
        exchangeRate:'',
        acceptanceCommission:0,
        telexCharge:'',
        postageCharge:'',
        total:0,
        company:''

    }
  );
  const [isFormFilled, setIsFormFilled] =useState(false)
  const {
        lcReferenceNo ,
        dcDate,
        billRef,
        billAmount,
        billMaturityDate,
        commissionRate,
        exchangeRate,
        acceptanceCommission,
        telexCharge,
        postageCharge,
        total,
        company
  } =formData
  console.log(formData);

  const handleInputChange =(e)=>{
    const {name, value} = e.target;
    setFormData({...formData,[name]:value});
  }

  // const calculateEquivalentInLKR = () => {
  //   const calEquivalentInLkr = (
  //     parseFloat(billAmount) * parseFloat(exchangeRate) * (parseFloat(commissionRate) / 100)
  //     );
  //   console.log(calEquivalentInLkr);
  //   setFormData(prev => ({ ...prev, acceptanceCommission:calEquivalentInLkr}));
  // };

  // const calculateTotCharge = () => {
  //   const total = parseFloat(formData.acceptanceCommission) + parseFloat(formData.telexCharge) + parseFloat(formData.postageCharge);
  //   setFormData(prevState => ({ ...prevState, total}));
  // };

  const calculateTotCharge = () => {

    const cal = (
      parseFloat(billAmount) * parseFloat(exchangeRate) * (parseFloat(commissionRate) / 100)
      );
    setFormData(prev => ({ ...prev, acceptanceCommission:cal}));

    const total = cal + parseFloat(formData.telexCharge) + parseFloat(formData.postageCharge);
    setFormData(prevState => ({ ...prevState, total}));
  };

  
  const calculateBtn= async() =>{
    // const isEmptyField = Object.values(formData).some(value => value === '');
    if (!company || !lcReferenceNo|| !dcDate || !billRef || !billAmount || !billMaturityDate || !commissionRate || !exchangeRate || !telexCharge || !postageCharge) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }
    await calculateTotCharge();

    setIsFormFilled(true)
    
  }

  const clearForm = () => {
    setFormData({
        lcReferenceNo:'',
        dcDate :'',
        billRef:'',
        billAmount:'',
        billMaturityDate:'',
        commissionRate:'',
        exchangeRate:'',
        acceptanceCommission:0,
        telexCharge:'',
        postageCharge:'',
        total:0,
        company:''
    });
    setIsFormFilled(false);
  };
  

  const saveFunc = async(e) =>{
    calculateBtn()
    e.preventDefault();

    const refExsist = await CheckAmdNo(lcReferenceNo);
    if(refExsist){
      Swal.fire({
        icon: 'warning',
        title: 'warning!',
        text: 'Ref number alrady used.',
        confirmButtonText: 'OK',
      }).then(()=>{
        return;
      })
    }

    calculateBtn();
     const data = await CreateDocument(formData);
     if(data){
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The LC Document Advice added successfully.',
        confirmButtonText: 'OK',
      }).then(()=>{
        clearForm();
      })
     }
    

    
  }

  useEffect(() => {
    async function fetchCompanies() {
      try {
          const response = await GetAllCompanies();
          if (response && response.data && Array.isArray(response.data)) {
            setCompanyData(response.data);
              console.log(response);
          } else {
              console.error('Invalid response data:', response);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }
    fetchCompanies();
}, []);



  return (
    <div>
      <h1>LC Document Collection</h1>

      <form className='pt-4 form-group' onSubmit={saveFunc}>

      <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
          <div className='col-sm-7'>
          <select name='company' value={company} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            {companyData.map((recordmap)=>(
              <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
            ))}
          </select>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>DC Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="dcDate" value={dcDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="billRef" value={billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Amount  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="billAmount" value={billAmount} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Exchange Rate  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="exchangeRate" value={exchangeRate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Maturity Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="billMaturityDate" value={billMaturityDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Commission Rate % </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="commissionRate" value={commissionRate} onChange={handleInputChange} min={0.00} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Acceptance Commission </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="acceptanceCommission" value={parseFloat(acceptanceCommission).toFixed(2)} onChange={handleInputChange} required  readOnly/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Telex Charge  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="telexCharge" value={telexCharge} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Postage  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="postageCharge" value={postageCharge} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Total </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="total" value={parseFloat(total).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>

        
        
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Document-table`}> List </Link></button>
          
        </div>
      </form>

    </div>
  )
}

export default LCDocument