import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetDocument } from '../../Service/LCDdocumentService';
import { GetAllCompanies } from '../../Service/CompanyService';
import jsPDF from 'jspdf';


const LCODocumentTable = () => {
    const [record, setRecord] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);


    useEffect(() => {
        async function fetchRecord() {
            try {
                const response = await GetDocument();
                if (response && response.data && Array.isArray(response.data)) {
                    setRecord(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchRecord();
    }, []);

    const formatDateString = (isoDateString) => {
        const isoDate = new Date(isoDateString);
        return isoDate.toLocaleString(); // Adjust the locale and options as needed
    };

    const filterRecord = record.filter((reco) =>
    (reco.lcReferenceNo?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.amendDate?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.company?.toLowerCase().includes(searchQuery.toLowerCase()) || '')

);

useEffect(() => {
    async function fetchCompanies() {
        try {
            const response = await GetAllCompanies();
            if (response && response.data && Array.isArray(response.data)) {
                setCompanyData(response.data);
                console.log(response);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    fetchCompanies();
}, []);



const exportToPdf = (reco) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("LC Document Collection", 10, 10);
    // const formattedTotalCharge = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(reco.totalCharge);
    // const formattedEquivalentInLkr = new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'INR' }).format(reco.equivalentInLkr);

    doc.setFontSize(12);
    doc.text(`LC Reference No: ${reco.lcReferenceNo}`, 10, 20);
    doc.text(`DC Date: ${formatDateString(reco.dcDate)}`, 10, 30);
    doc.text(`Bill Ref: ${reco.billRef}`, 10, 40);
    doc.text(`Bill Amount: ${reco.billAmount}`, 10, 50);
    doc.text(`Exchange Rate: ${reco.exchangeRate}`, 10, 60);
    doc.text(`Bill Maturity Date: ${formatDateString(reco.billMaturityDate)}`, 10, 70);
    doc.text(`Commission Rate %: ${reco.commissionRate}`, 10, 80);
    doc.text(`Acceptance Commission: ${reco.acceptanceCommission}`, 10, 90);
    doc.text(`Telex Charge:${reco.telexCharge} `, 10, 100);
    doc.setFont("helvetica", "bold");
    doc.text(`Total: ${reco.total}`, 10, 110);
    doc.setFont("helvetica", "normal");
    doc.text(`Company Name: ${reco.company}`,10,120)

    doc.save(`${reco.lcReferenceNo}.pdf`);
};


    return (
        <div>
            <h1>LC Document Collection Table</h1>
            <div className="d-flex justify-content-end mt-3">
                <input type='text' placeholder='Search' className='form-control w-25 m-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                <select value={searchQuery} class="form-control w-25 m-1" aria-label="Disabled select example" onChange={(e) => setSearchQuery(e.target.value)} >
                    <option value='' selected>All</option>
                    {companyData.map((recordmap)=>(
                    <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
                    ))}
                </select>

            </div>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>LC Reference No</th>
                        <th>Company</th>
                        <th>Bill Ref</th>
                        <th>DC Date</th>
                        <th className='text-end'>Acceptance Commission</th>
                        <th className='text-end'>Total</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filterRecord.length > 0 ? (
                        filterRecord.map((reco) => (
                            <tr key={reco.lcReferenceNo}>
                                <td>{reco.lcReferenceNo}</td>
                                <td>{reco.company}</td>
                                <td>{reco.billRef}</td>
                                <td>{formatDateString(reco.dcDate)}</td>
                                <td className="text-end">{reco.acceptanceCommission}</td>
                                <td className="text-end">{reco.total}</td>
                                <td>
                                    <button className='btn btn-success'><Link className="text-decoration-none text-white" to={`/Lc-Document/${reco.lcReferenceNo}`}>Update</Link></button>
                                    <button className='btn btn-info m-1' onClick={() => exportToPdf(reco)}>Export</button>

                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No matching record found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default LCODocumentTable;
