import React, { useEffect, useState } from 'react';
import { GetLCOenningAdvice } from '../Service/LoanService';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import { GetAllCompanies } from '../Service/CompanyService';

const LCOAdvicetable = () => {
    const [record, setRecord] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);


    useEffect(() => {
        async function fetchRecord() {
            try {
                const response = await GetLCOenningAdvice();
                if (response && response.data && Array.isArray(response.data)) {
                    setRecord(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchRecord();
    }, []);

    const formatDateString = (isoDateString) => {
        const isoDate = new Date(isoDateString);
        return isoDate.toLocaleString(); // Adjust the locale and options as needed
    };

    const filterRecord = record.filter((reco) =>
    (reco.lcReferenceNo?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.amendDate?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.company?.toLowerCase().includes(searchQuery.toLowerCase()) || '')

);

const exportToPdf = (reco) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("LC Opening Advice", 10, 10);
    // const formattedTotalCharge = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(reco.totalCharge);
    // const formattedEquivalentInLkr = new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'INR' }).format(reco.equivalentInLkr);

    doc.setFontSize(12);
    doc.text(`LC Reference No: ${reco.lcReferenceNo}`, 10, 20);
    doc.text(`LCO Date: ${formatDateString(reco.lcoDate)}`, 10, 30);
    doc.text(`Forv: ${reco.forv}`, 10, 40);
    doc.text(`Tolerance: ${reco.tolerance}`, 10, 50);
    doc.text(`Issued On: ${formatDateString(reco.issuedOn)}`, 10, 60);
    doc.text(`Favor Of: ${reco.favorof}`, 10, 70);
    doc.text(`Issuance Commission: ${reco.issuanceCommission}`, 10, 80);
    doc.text(`Rate Of Conversion: ${reco.rateOfConversion}`, 10, 90);
    doc.setTextColor(0, 0, 0);
    doc.text(`Equivalent In LKR:${reco.equivalentInLkr} `, 10, 100);
    doc.text(`Third Party Commission: ${reco.thirdPartyCommission}`, 10, 110);
    doc.text(`Postage Charge: ${reco.postageCharge}`, 10, 120);
    doc.text(`Swift Charge: ${reco.swiftCharge}`, 10, 130);
    doc.text(`Other Charge: ${reco.otherCharge}`, 10, 140);
    doc.text(`Arrangement Fee: ${reco.arrangementFee}`, 10, 150);
    doc.text(`Total Charge: ${reco.totalCharge}</b>`, 10, 160);;
    doc.text(`Company Name: ${reco.company}`,10,1700)

    doc.save(`${reco.lcReferenceNo}.pdf`);
};





useEffect(() => {
    async function fetchCompanies() {
        try {
            const response = await GetAllCompanies();
            if (response && response.data && Array.isArray(response.data)) {
                setCompanyData(response.data);
                console.log(response);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    fetchCompanies();
}, []);






    return (
        <div>
            <h1>LO Opening Advice Table</h1>
            <div className="d-flex justify-content-end mt-3">
                <input type='text' placeholder='Search' className='form-control w-25 m-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                <select value={searchQuery} class="form-control w-25 m-1" aria-label="Disabled select example" onChange={(e) => setSearchQuery(e.target.value)} >
                    <option value='' selected>All</option>
                    {companyData.map((recordmap)=>(
                    <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
                    ))}
                </select>
            </div>
            <div className="d-flex justify-content-end mt-3">
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>LC Reference No</th>
                        <th>Company</th>
                        <th>LCO Date</th>
                        <th>Issued On</th>
                        <th>Equivalent in LKR</th>
                        <th>Total Charge</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filterRecord.length > 0 ? (
                        filterRecord.map((reco) => (
                            <tr key={reco.lcReferenceNo}>
                                <td>{reco.lcReferenceNo}</td>
                                <td>{reco.company}</td>
                                <td>{formatDateString(reco.lcoDate)}</td>
                                <td>{formatDateString(reco.issuedOn)}</td>
                                <td className="text-end">{reco.equivalentInLkr}</td>
                                <td className="text-end">{reco.totalCharge}</td>
                                <td>
                                    <button className='btn btn-success m-1'><Link className="text-decoration-none text-white" to={`/Lc-Advice/${reco.lcReferenceNo}`}>Update</Link></button>
                                    <button className='btn btn-info m-1' onClick={() => exportToPdf(reco)}>Export</button>                                
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No matching record found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default LCOAdvicetable;
