import React, { useEffect, useState } from 'react'
import { Await, Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { GetByIdEnhancement, updateEnhancement } from '../../Service/LCEnhancementService';
import { GetLCOenningAdvice } from '../../Service/LoanService';

const LCWnhancementupdate = () => {
    const { amdno, lcRefNo } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [record, setRecord] = useState([]);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
      amendmentNo :'',
      lcReferenceNo :'',
      enhancementDate :formatDate(new Date()),
      forv :'',
      amendCommission :'',
      exchangeRate :'',
      equivalentInLkr :0.00,
      thirdPartyCommission :'',
      postageCharge :'',
      swiftCharge :'',
      otherCharge :'',
      arrangementFee :'',
      totalDebitAmount :'',
      issuedOn:formatDate(new Date()),
      favorof:''
    });

    const {
      amendmentNo,
      lcReferenceNo,
      enhancementDate,
      forv,
      amendCommission,
      exchangeRate,
      equivalentInLkr,
      thirdPartyCommission,
      postageCharge,
      swiftCharge,
      otherCharge,
      arrangementFee,
      totalDebitAmount,
      issuedOn,
      favorof,
} =formData
        
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdEnhancement(amdno, lcRefNo);
                setRecordData(data);
                setFormData(data);
                console.log(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
      calculateBtn();
        e.preventDefault();
        try {
            await updateEnhancement(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-Advice/${formData.lcReferenceNo}`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const calculateTotCharge = () => {
      const calEquivalentInLkr = (parseFloat(forv) * parseFloat(forv) * (parseFloat(amendCommission) / 100.0));
      setFormData(prevState => ({ ...prevState, equivalentInLkr:calEquivalentInLkr }));
  
      const tot = calEquivalentInLkr + parseFloat(thirdPartyCommission) + parseFloat(postageCharge) + parseFloat(swiftCharge) + parseFloat(otherCharge) + parseFloat(arrangementFee);
      setFormData(prevState => ({ ...prevState, totalDebitAmount: isNaN(tot) ? '' : tot }));
    };
    const calculateBtn = () => {
      if (!amendmentNo|| !lcReferenceNo || !enhancementDate || !forv || !amendCommission || !exchangeRate  || !thirdPartyCommission || !postageCharge || !swiftCharge || !otherCharge || !arrangementFee || !issuedOn || !favorof) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }
      calculateTotCharge();
      setIsFormFilled(true)
    };


    const clearForm = () => {
      setFormData({
        amendmentNo :'',
          lcReferenceNo :'',
          enhancementDate :'',
          forv :'',
          amendCommission :'',
          exchangeRate :'',
          equivalentInLkr :0.00,
          thirdPartyCommission :'',
          postageCharge :'',
          swiftCharge :'',
          otherCharge :'',
          arrangementFee :'',
          totalDebitAmount :'',
          issuedOn:'',
          favorof:''
      });
      setIsFormFilled(false);
    };

      //aouto filling
  useEffect(() => {
    async function fetchRecord() {
        try {
            const response = await GetLCOenningAdvice();
            if (response && response.data && Array.isArray(response.data)) {
                setRecord(response.data);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    fetchRecord();
}, []);

const recordFilteredByLcReferenceNo = record.find(item => item.lcReferenceNo === lcReferenceNo);

useEffect(() => {
    if (recordFilteredByLcReferenceNo) {
        setFormData(prevState => ({
            ...prevState,
            issuedOn: recordFilteredByLcReferenceNo.issuedOn,
            favorof: recordFilteredByLcReferenceNo.favorof,
            
        }));
    }
}, [recordFilteredByLcReferenceNo]);
    



  return (
    <div>
      <h1>LC Enhancement Advice Update</h1>

      <form className='pt-4 form-group' onSubmit={updateFunc}>

        <div className='d-flex'>
          <div className='pe-2 m-4' style={{width:'70vh'}}>

          <div className='form-group row p-2'>
      <label className='col-sm-4 col-form-label'>Company</label>
        <div className='col-sm-7'>
        <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
            <option selected>Select</option>
              <option value={formData.company}>{formData.company}</option>
          </select>
        </div>
      </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Enhancement  Date </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control"  name="enhancementDate"  value={formData.enhancementDate ? new Date(formData.enhancementDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Amendment No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control" name="amendmentNo" value={formData.amendmentNo} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Issued On </label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="issuedOn"  value={formData.issuedOn ? new Date(formData.issuedOn).toLocaleDateString('en-CA') : ''}  onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Favor of</label>
          <div className='col-sm-7'>
            <input type="text" class="form-control" name="favorof" value={formData.favorof} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>For</label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="forv" value={formData.forv} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Amend Commission </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="amendCommission" value={formData.amendCommission} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Exchange Rate  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="exchangeRate" value={formData.exchangeRate} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        {/* auto cal */}
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Equivalent in LKR </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="equivalentInLkr" value={formData.equivalentInLkr} onChange={handleInputChange} readOnly />
          </div>
        </div>

        </div>
        <div className='m-4' style={{width:'70vh'}}> 


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Third Party Commission </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="thirdPartyCommission" value={formData.thirdPartyCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Postage Charge </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="postageCharge" value={formData.postageCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Swift Charge  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="swiftCharge" value={formData.swiftCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Other Charge   </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="otherCharge" value={formData.otherCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Arrangement Fee  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="arrangementFee" value={formData.arrangementFee} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Total Debit Amount </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="totalDebitAmount" value={formData.totalDebitAmount} onChange={handleInputChange} min={0.00} readOnly/>
          </div>
        </div>

        </div>
        
        </div>
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Enhancement-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCWnhancementupdate