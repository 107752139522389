import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 bg-dark " style={{ width: '20%', }}>
      <ul className="nav nav-pills flex-column mb-auto pt-4 ">

        <div className='bg-warning m-3 pt-2 pb-2 text-center z-index-2'>
        <li className="nav-item ">
          <Link to="/Lc-Advice" className="nav-link text-white">
            <p className='text-dark'>LC Opening Advice</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center '>
        <li className="nav-item">
          <Link to="/Lc-Amendment" className="nav-link text-white">
            <p className='text-dark'>LC Amendment Advice</p>
          </Link>
        </li>
        </div>
        
        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/Lc-Enhancement" className="nav-link text-white">
            <p className='text-dark'>LC Enchancement Advise</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/Lc-Document" className="nav-link text-white">
          <p className='text-dark'>LC Document Advise</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/Lc-Settlement" className="nav-link text-white">
          <p className='text-dark'>LC Settlement Advise</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/Lc-shipping" className="nav-link text-white">
          <p className='text-dark'>Shipping Guaranty Advice</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/loan-granting" className="nav-link text-white">
          <p className='text-dark'>Loan Granting</p>
          </Link>
        </li>
        </div>

        <div className='bg-warning m-3 pt-2 pb-2 text-center'>
        <li className="nav-item">
          <Link to="/Ril-settlement" className="nav-link text-white">
          <p className='text-dark'>RIL Settlement Advice</p>
          </Link>
        </li>
        </div>

      </ul>
    </div>
  )
}

export default Sidebar