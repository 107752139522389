import React, { useEffect, useState } from 'react'
import { Await, Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { GetByIdLCOenningAdvice, updateLCOenningAdvice } from '../Service/LoanService';

const LCOpeningAdviceupdate = () => {
    const { refno } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
        lcReferenceNo:'',
        lcoDate:new Date(),
        forv:'',
        tolerance:0,
        issuedOn:'',
        favorof:'',
        issuanceCommission:'',
        rateOfConversion:'',
        equivalentInLkr:'',
        thirdPartyCommission:'',
        postageCharge:'',
        swiftCharge:'',
        otherCharge:'',
        arrangementFee:'',
        totalCharge:'',
    });

    const {
      lcReferenceNo,
      lcoDate,
      forv,
      tolerance,
      issuedOn,
      favorof,
      issuanceCommission,
      rateOfConversion,
      equivalentInLkr,
      thirdPartyCommission,
      postageCharge,
      swiftCharge,
      otherCharge,
      arrangementFee,
      totalCharge,
} =formData


   
    
    
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdLCOenningAdvice(refno);
                setRecordData(data);
                setFormData(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
      calculateBtn()
        e.preventDefault();
        try {
            await updateLCOenningAdvice(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-Advice/${formData.lcReferenceNo}`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const calculateEquivalentInLKR = () => {
    //   const calEquivalentInLkr = (
    //     parseFloat(formData.forv) * parseFloat(formData.tolerance)/100 * parseFloat(formData.rateOfConversion)*parseFloat(formData.issuanceCommission)/100
    //   )
    //   setFormData(prevState => ({ ...prevState, equivalentInLkr: calEquivalentInLkr }));
  
    // };
  
    // const calculateTotCharge = () => {
    //   const totalCharge = parseFloat(formData.equivalentInLkr) + parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee)
    //   setFormData(prev=> ({...prev,totalCharge}));
    // };

    const calculateTotCharge = () => {
      const calEquivalentInLkr = (
        parseFloat(formData.forv) * parseFloat(formData.tolerance)/100 * parseFloat(formData.rateOfConversion)*parseFloat(formData.issuanceCommission)/100
      )
      setFormData(prevState => ({ ...prevState, equivalentInLkr: calEquivalentInLkr }));
  
  
      const tot = calEquivalentInLkr + parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee)
      setFormData(prev=> ({...prev,totalCharge:tot}));
    };
    

    const calculateBtn = () => {
        // calculateEquivalentInLKR();
        if (!lcReferenceNo || !lcoDate || !forv || !tolerance || !issuedOn || !favorof || !issuanceCommission || !rateOfConversion || !thirdPartyCommission || !postageCharge || !swiftCharge || !otherCharge || !arrangementFee) {
          Swal.fire({
              icon: 'warning',
              title: 'Warning!',
              text: 'Please fill all the input fields before calculating.',
              confirmButtonText: 'OK',
          });
          return;
      }
        calculateTotCharge();
        setIsFormFilled(true)
    };


    const clearForm = () => {
        // setFormData(initialState);
        setIsFormFilled(false);
      };
    



  return (
    <div>
    <h1>LC Opening Advice Update</h1>

    <form className='pt-4 form-group' onSubmit={updateFunc}>

      <div className='d-flex'>
      <div>

      <div className='form-group row p-2'>
      <label className='col-sm-3 col-form-label'>Company</label>
        <div className='col-sm-9'>
        <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
            <option selected>Select</option>
              <option value={formData.company}>{formData.company}</option>
          </select>
        </div>
      </div>



      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>LC Reference No</label>
        <div className='col-sm-9'>
          <input type="text" class="form-control"  name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} readOnly required />
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>LCO Date</label>
        <div className='col-sm-9'>
          <input type="date" class="form-control" name="lcoDate" value={formData.lcoDate ? new Date(formData.lcoDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>For</label>
        <div className='col-sm-9'>
          <input type="number" min={0} class="form-control" name="forv" value={formData.forv} onChange={handleInputChange} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Tolerance</label>
        <div className='col-sm-9'>
          <input type="number" min={0} class="form-control" name="tolerance" value={formData.tolerance} onChange={handleInputChange} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Issued On</label>
        <div className='col-sm-9'>
          <input type="date" class="form-control" name="issuedOn" value={formData.issuedOn  ? new Date(formData.lcoDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Favor of</label>
        <div className='col-sm-9'>
          <input type="text" class="form-control" name="favorof" value={formData.favorof} onChange={handleInputChange} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Issuance Commission </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="issuanceCommission" value={formData.issuanceCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className="form-group row p-2">
        <label class="col-sm-3 col-form-label">Rate of Conversion </label>
        <div className='col-sm-9'>
          <input type="number" min={0} class="form-control" name="rateOfConversion" value={formData.rateOfConversion} onChange={handleInputChange} required  readOnly={isFormFilled}/>
        </div>
      </div>

      {/* auto cal */}
      <div className="form-group row p-2">
        <label class="col-sm-3 col-form-label">Equivalent in LKR </label>
        <div className='col-sm-9'>
          <input type="number" min={0} class="form-control" name="equivalentInLkr" value={parseFloat(formData.equivalentInLkr).toFixed(2)} onChange={handleInputChange} readOnly />
        </div>
      </div>

      </div>
      <div>


      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Third Party Commission </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="thirdPartyCommission" value={formData.thirdPartyCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Postage Charge </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="postageCharge" value={formData.postageCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>


      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Swift Charge  </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="swiftCharge" value={formData.swiftCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Other Charge   </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="otherCharge" value={formData.otherCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>

      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Arrangement Fee  </label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="arrangementFee" value={formData.arrangementFee} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
        </div>
      </div>


      <div className='form-group row p-2'>
        <label className='col-sm-3 col-form-label'>Amount</label>
        <div className='col-sm-9'>
          <input type="number" class="form-control" name="totalCharge" value={parseFloat(formData.totalCharge).toFixed(2)} onChange={handleInputChange} min={0.00} readOnly/>
        </div>
      </div>

      </div>
      
      </div>
      <div>
        <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
        <button type='submit' className='btn btn-success m-2'>Save</button>
        {isFormFilled && <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>}
        <button className='btn btn-success m-4' ><Link className="text-decoration-none text-white" to={`/Lc-Advice-table`}> List </Link></button>
        
      </div>
    </form>
  </div>
  )
}

export default LCOpeningAdviceupdate