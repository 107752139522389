import axios from "axios"
import { toast } from "react-toastify";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


export const CreateShipping=async(formData)=>{
    console.log(formData);
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/shipping`,formData);
        if(responce==="OK"){
            toast.success("Added")
        }
        return responce
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetShipping =async()=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/shipping`);
        return responce;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const GetByIdShipping =async(amdno)=>{
    try{
        const responce = await axios.get(`${BACKEND_URL}/api/shipping/${amdno}`);
        return responce.data;
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const updateShipping = async(formData)=>{
    try{
        const responce = await axios.patch(`${BACKEND_URL}/api/shipping`,formData)
        return responce.data


    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}


export const CheckAmdNo = async(formData)=>{
    try{
        const responce = await axios.post(`${BACKEND_URL}/api/shipping/check/${formData}`)
        return responce.data


    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message
        ) || error.message || error.toString()
        toast.error(message)
    }
}