import React, { useEffect, useState } from 'react'
import { CheckRefNo, CreateLCOenningAdvice } from '../Service/LoanService';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { GetAllCompanies } from '../Service/CompanyService';

const initialState = {
        lcReferenceNo:'',
        lcoDate:'',
        forv:'',
        tolerance:'',
        issuedOn:'',
        favorof:'',
        issuanceCommission:'',
        rateOfConversion:'',
        equivalentInLkr:'',
        thirdPartyCommission:'',
        swiftCharge:'',
        otherCharge:'',
        arrangementFee:'',
        totalCharge:'',
        company:'',
};

const LCOpeningAdvice = () => {
  const [formData, setFormData] =useState(initialState);
  const [isFormFilled, setIsFormFilled] =useState(false)
  const [isEmpty,setIsEmpty] = useState(true)
  const [record, setRecord] = useState([]);

  const {
        lcReferenceNo,
        lcoDate,
        forv,
        tolerance,
        issuedOn,
        favorof,
        issuanceCommission,
        rateOfConversion,
        equivalentInLkr,
        thirdPartyCommission,
        postageCharge,
        swiftCharge,
        otherCharge,
        arrangementFee,
        totalCharge,
        company
  } =formData

  const handleInputChange =(e)=>{
    const {name, value} = e.target;
    setFormData({...formData,[name]:value});

    }


    useEffect(() => {
      async function fetchCompanies() {
          try {
              const response = await GetAllCompanies();
              if (response && response.data && Array.isArray(response.data)) {
                  setRecord(response.data);
              } else {
                  console.error('Invalid response data:', response);
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      }
      fetchCompanies();
  }, []);

  // const calculateEquivalentInLKR = () => {
  //   const calEquivalentInLkr = (
  //     parseFloat(formData.forv) * parseFloat(formData.tolerance)/100 * parseFloat(formData.rateOfConversion)*parseFloat(formData.issuanceCommission)/100
  //   )
  //   setFormData(prevState => ({ ...prevState, equivalentInLkr: calEquivalentInLkr }));
  // };

  // const calculateTotCharge = () => {
  //   const tot = parseFloat(formData.equivalentInLkr) + parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee)
  //   setFormData(prev=> ({...prev,totalCharge:tot}));
  // };

  const calculateTotCharge = () => {
    const calEquivalentInLkr = (
      (parseFloat(formData.forv) * parseFloat(formData.tolerance) * parseFloat(formData.rateOfConversion)*parseFloat(formData.issuanceCommission))/10000.0
    )
    setFormData(prevState => ({ ...prevState, equivalentInLkr: calEquivalentInLkr }));


    const tot = calEquivalentInLkr + parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee)
    setFormData(prev=> ({...prev,totalCharge:tot}));
  };
  

  const calculateBtn= () =>{
    //  calculateEquivalentInLKR();
     
    // const isEmptyField = Object.values(formData).some(value => value === '');
    console.log(formData);

    if (!company || !lcReferenceNo || !lcoDate || !forv || !tolerance || !issuedOn || !favorof || !issuanceCommission || !rateOfConversion || !thirdPartyCommission || !postageCharge || !swiftCharge || !otherCharge || !arrangementFee) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }
    
    calculateTotCharge();
    setIsFormFilled(true)
  }

  const clearForm = () => {
    setFormData(initialState);
    setIsFormFilled(false);
  };


  const saveFunc = async(e) =>{
    e.preventDefault();

    const refExsist = await CheckRefNo(lcReferenceNo);
    if(refExsist){
      Swal.fire({
        icon: 'warning',
        title: 'warning!',
        text: 'Ref number alrady used.',
        confirmButtonText: 'OK',
      }).then(()=>{
        return;
      })
    }

    calculateBtn();
    const data = await CreateLCOenningAdvice(formData);
    if(data){
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The LC Opening Advice added successfully.',
        confirmButtonText: 'OK',
      }).then(()=>{
        clearForm();
      })
    }

    
  }



  return (
    <div>
      <h1>LC Opening Advice</h1>

      <form className='pt-4 form-group' onSubmit={saveFunc}>

        <div className='d-flex'>
          <div className='pe-2 m-4' style={{width:'70vh'}}>

          <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
          <div className='col-sm-7'>
          <select name='company' value={company} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            {record.map((recordmap)=>(
              <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
            ))}
          </select>
          </div>
        </div>

        
        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No</label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LCO Date</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="lcoDate" value={lcoDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>For</label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="forv" value={forv} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Tolerance</label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="tolerance" value={tolerance} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Issued On</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="issuedOn" value={issuedOn} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Favor of</label>
          <div className='col-sm-7'>
            <input type="text" class="form-control" name="favorof" value={favorof} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Issuance Commission </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="issuanceCommission" value={issuanceCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Rate of Conversion </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="rateOfConversion" value={rateOfConversion} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        {/* auto cal */}
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Equivalent in LKR </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="equivalentInLkr" value={parseFloat(equivalentInLkr).toFixed(2)} onChange={handleInputChange} readOnly />
          </div>
        </div>

        </div>
        <div className='m-4' style={{width:'70vh'}}>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Third Party Commission </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="thirdPartyCommission" value={thirdPartyCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Postage Charge </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="postageCharge" value={postageCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Swift Charge  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="swiftCharge" value={swiftCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Other Charge   </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="otherCharge" value={otherCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Arrangement Fee  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="arrangementFee" value={arrangementFee} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Amount</label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="totalCharge" value={parseFloat(totalCharge).toFixed(2)} onChange={handleInputChange} min={0.00} readOnly/>
          </div>
        </div>

        </div>
        
        </div>
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2 pl-4'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Advice-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCOpeningAdvice