import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { CheckAmdNo, CreateLCAmendment } from '../../Service/LCAmendmentService';
import { GetLCOenningAdvice } from '../../Service/LoanService';
import { GetAllCompanies } from '../../Service/CompanyService';


const LCAmendment = () => {

  const [isFormFilled, setIsFormFilled] =useState(false)
  const [record, setRecord] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const [formData, setFormData] = useState({
    amendmentNo: '',
    lcReferenceNo: '',
    amendDate:'',
    forv: '',
    amendCommission: '',
    exchangeRate: '',
    equivalentInLkr: '',
    thirdPartyCommission: '',
    postageCharge: '',
    swiftCharge: '',
    otherCharge: '',
    arrangementFee: '',
    totalDebitAmountAmount: '',
    issuedOn: '',
    favorof: '',
    company:''
});
  

  const {
    amendmentNo,
    lcReferenceNo,
    amendDate,
    forv,
    amendCommission,
    exchangeRate,
    equivalentInLkr,
    thirdPartyCommission,
    postageCharge,
    swiftCharge,
    otherCharge,
    arrangementFee,
    totalDebitAmountAmount,
    issuedOn,
    favorof,
    company
  } =formData

  

  const handleInputChange =(e)=>{
    const {name, value} = e.target;
    setFormData({...formData,[name]:value});
  }

  // const calculateEquivalentInLKR = () => {
  //   const calEquivalentInLkr = (
  //     parseFloat(formData.forv) *parseFloat(formData.exchangeRate)* (parseFloat(formData.amendCommission) / 100) 
  //     );

  //     setFormData(prev => ({ ...prev, equivalentInLkr: calEquivalentInLkr }));
  // };

  // const calculateTotCharge = () => {
  //   const totalDebitAmountAmount = (parseFloat(formData.equivalentInLkr) + parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee));
  //   setFormData(prev => ({ ...prev, totalDebitAmountAmount}));
  // };


  const calculateTotCharge = () => {
    const calEquivalentInLkr = (
      parseFloat(formData.forv) *parseFloat(formData.exchangeRate)* (parseFloat(formData.amendCommission) / 100) 
      );

      setFormData(prev => ({ ...prev, equivalentInLkr: calEquivalentInLkr }));


    const totalDebitAmountAmount = (calEquivalentInLkr+ parseFloat(formData.thirdPartyCommission) + parseFloat(formData.postageCharge) + parseFloat(formData.swiftCharge) + parseFloat(formData.otherCharge) + parseFloat(formData.arrangementFee));
    setFormData(prev => ({ ...prev, totalDebitAmountAmount}));
  };

  const calculateBtn= async() =>{
    //  calculateEquivalentInLKR();
    

    // const isEmptyField = Object.values(formData).some(value => value === '');
    if (!company ||!amendmentNo || !lcReferenceNo || !amendDate || !forv || !amendCommission || !exchangeRate || !thirdPartyCommission || !postageCharge || !swiftCharge || !otherCharge || !arrangementFee || !issuedOn || !favorof) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }
    await calculateTotCharge();
    
    setIsFormFilled(true)
  }

  const clearForm = () => {
    setFormData({
      amendmentNo: '',
      lcReferenceNo: '',
      amendDate:'',
      forv: '',
      amendCommission: '',
      exchangeRate: '',
      equivalentInLkr: '',
      thirdPartyCommission: '',
      postageCharge: '',
      swiftCharge: '',
      otherCharge: '',
      arrangementFee: '',
      totalDebitAmountAmount: '',
      issuedOn: '',
      favorof: '',
      company:''
    });
    setIsFormFilled(false);
  };

  const saveFunc = async(e) =>{
    e.preventDefault();

    const refExsist = await CheckAmdNo(lcReferenceNo,amendmentNo);
    if(refExsist){
      Swal.fire({
        icon: 'warning',
        title: 'warning!',
        text: 'Ref number alrady used.',
        confirmButtonText: 'OK',
      }).then(()=>{
        return;
      })
    }

    await calculateBtn();
   const data =  await CreateLCAmendment(formData);
    if(data){
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The LC Opening Advice added successfully.',
        confirmButtonText: 'OK',
      }).then(()=>{
        clearForm();
      })
    }
  }

  useEffect(() => {
    async function fetchRecord() {
        try {
            const response = await GetLCOenningAdvice();
            if (response && response.data && Array.isArray(response.data)) {
                setRecord(response.data);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function fetchCompanies() {
      try {
          const response = await GetAllCompanies();
          if (response && response.data && Array.isArray(response.data)) {
            setCompanyData(response.data);
              console.log(response);
          } else {
              console.error('Invalid response data:', response);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }
    fetchRecord();
    fetchCompanies();
}, []);

const recordFilteredByLcReferenceNo = record.find(item => item.lcReferenceNo === lcReferenceNo);

useEffect(() => {
    if (recordFilteredByLcReferenceNo) {
        setFormData(prevState => ({
            ...prevState,
            issuedOn: recordFilteredByLcReferenceNo.issuedOn,
            favorof: recordFilteredByLcReferenceNo.favorof,
            
        }));
    }
}, [recordFilteredByLcReferenceNo]);

console.log(issuedOn);



  return (
    <div>
      <h1>LC Amendment Advice</h1>

      <form className='pt-4 form-group' onSubmit={saveFunc}>

        <div className='d-flex'>
          <div className='pe-2 m-4' style={{width:'70vh'}}>


          <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
          <div className='col-sm-7'>
          <select name='company' value={company} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            {companyData.map((recordmap)=>(
              <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
            ))}
          </select>
          </div>
        </div>



          
        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Amendment Date </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control"  name="amendDate" value={amendDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Amendment No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control" name="amendmentNo" value={amendmentNo} onChange={ handleInputChange}  required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
                <label className='col-sm-4 col-form-label'>LC Reference No </label>
                <div className='col-sm-7'>
                    <input type="text" className="form-control" name="lcReferenceNo" value={lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
                </div>
            </div>

            <div className='form-group row p-2'>
                <label className='col-sm-4 col-form-label'>Issued On </label>
                <div className='col-sm-7'>
                    <input type="date" className="form-control" name="issuedOn" value={formData.issuedOn ? new Date(formData.issuedOn).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly/>
                </div>
            </div>

            <div className='form-group row p-2'>
                <label className='col-sm-4 col-form-label'>Favor of</label>
                <div className='col-sm-7'>
                    <input type="text" className="form-control" name="favorof" value={formData.favorof} onChange={handleInputChange} required readOnly/>
                </div>
            </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>For</label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="forv" value={forv} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Amend Commission </label>
          <div className='col-sm-7'>
            <input type="number"  class="form-control" name="amendCommission" value={amendCommission} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>
        {/* check backen */}

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Exchange Rate  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="exchangeRate" value={exchangeRate} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        {/* auto cal */}
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Equivalent in LKR </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="equivalentInLkr" value={equivalentInLkr} onChange={handleInputChange} readOnly />
          </div>
        </div>

        </div>
        <div className='m-4' style={{width:'70vh'}}> 


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Third Party Commission </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="thirdPartyCommission" value={thirdPartyCommission} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Postage Charge </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="postageCharge" value={postageCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Swift Charge  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="swiftCharge" value={swiftCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Other Charge   </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="otherCharge" value={otherCharge} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Arrangement Fee  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="arrangementFee" value={arrangementFee} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Total Debit Amount </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="totalDebitAmountAmount" value={totalDebitAmountAmount} onChange={handleInputChange} min={0.00} readOnly/>
          </div>
        </div>

        </div>
        
        </div>
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-4' ><Link className="text-decoration-none text-white" to={`/Lc-Amendment-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCAmendment