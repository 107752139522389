import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetByIdRILSettlement, updateRILSettlement } from '../../Service/RILSettlemantService';


const RILSettlementupdate = () => {
    const { amdno } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [record, setRecord] = useState([]);

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
        lcReferenceNo:'',
        billRef:'',
        rilDate: new Date(),
        loanRef:'',
        loanGrantedAmount:'',
        loanBalanceAfterRecovery:'',
        maturityDate:new Date(),
        loanInterestRate:'',
        loanOverdueRate:'',
        from1:new Date(),
        to1:new Date(),
        days1:'',
        rate1:'',
        value1:0,
        from2:new Date(),
        to2:new Date(),
        days2:'',
        rate2:'',
        value2:0,
        recoveryValueDate:'',
        recoveredCapitalAmount:'',
        recoveredInterestAmount:0,
        totalAmount:0,
    });

    const {
        lcReferenceNo,
        billRef,
        rilDate,
        loanRef,
        loanGrantedAmount,
        loanBalanceAfterRecovery,
        maturityDate,
        loanInterestRate,
        loanOverdueRate,
        from1,
        to1,
        days1,
        rate1,
        value1,
        from2,
        to2,
        days2,
        rate2,
        value2,
        recoveryValueDate,
        recoveredCapitalAmount,
        recoveredInterestAmount,
        totalAmount,
} =formData
        
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdRILSettlement(amdno);
                setRecordData(data);
                setFormData(data);
                console.log(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
        e.preventDefault();
        try {
            await updateRILSettlement(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-Document/${formData.lcReferenceNo}`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const calculateValue1 = () => {
        const calculatedValue1 = (
          parseFloat(formData.recoveredCapitalAmount) *
          parseFloat(formData.days1) *
          (1/365) *
          (parseFloat(formData.rate1) / 100)
        );
        setFormData(prev => ({ ...prev, value1: calculatedValue1 }));
      };
    
      const calculateValue2 = () => {
        const calculatedValue2 = (
          parseFloat(formData.recoveredCapitalAmount) *
          parseFloat(formData.days2) *
          (1/365) *
          (parseFloat(formData.rate2) / 100)
        );
        setFormData(prev => ({ ...prev, value2: calculatedValue2 }));
      };
    
      const calculateRecoveredInterestAmount = () => {
        const recoveredInterestAmount = parseFloat(formData.value1) + parseFloat(formData.value2);
        setFormData(prev => ({ ...prev, recoveredInterestAmount }));
      };
    
      const calculateTotalAmount = () => {
        const totalAmount = parseFloat(formData.recoveredCapitalAmount) + parseFloat(formData.recoveredInterestAmount);
        setFormData(prev => ({ ...prev, totalAmount }));
      }
    
      
      const calculateBtn= async() =>{
        await calculateValue1();
        await calculateValue2();
        await calculateRecoveredInterestAmount();
        await calculateTotalAmount();
    
        
        
        const isEmptyField = Object.values(formData).some(value => value === '');
        if (isEmptyField) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please fill all the input fields before calculating.',
                confirmButtonText: 'OK',
            });
            return;
        }
    
        setIsFormFilled(true)
        
      }

    const clearForm = () => {
      setFormData({
        lcReferenceNo:'',
        billRef:'',
        rilDate: '',
        loanRef:'',
        loanGrantedAmount:'',
        loanBalanceAfterRecovery:'',
        maturityDate:'',
        loanInterestRate:'',
        loanOverdueRate:'',
        from1:'',
        to1:'',
        days1:'',
        rate1:'',
        value1:0,
        from2:'',
        to2:'',
        days2:'',
        rate2:'',
        value2:0,
        recoveryValueDate:'',
        recoveredCapitalAmount:'',
        recoveredInterestAmount:0,
        totalAmount:0,
      });
      setIsFormFilled(false);
      };




  return (
    <div>
      <h1>RIL / STL Advice Update</h1>

      <form className='pt-4 form-group' onSubmit={updateFunc}>

      <div className='d-flex'>
          <div className='pe-2 m-4' style={{width:'70vh'}}>

          <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
            <div className='col-sm-7'>
            <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
                <option selected>Select</option>
                  <option value={formData.company}>{formData.company}</option>
              </select>
            </div>
          </div>

          <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>RIL/STL Type</label>
          <div className='col-sm-7'>
          <select name='rsttype' value={formData.rsttype} class="form-select" aria-label="Disabled select example" required disabled>
            <option selected>{formData.rsttype}</option>
            {/* <option value="RIL">RIL</option>
            <option value="STL">STL</option> */}
          </select>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref</label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="billRef" value={formData.billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>RIL Date</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="rilDate" value={formData.rilDate ? new Date(formData.rilDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Ref  </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="loanRef" value={formData.loanRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Granted Amount </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="loanGrantedAmount" value={formData.loanGrantedAmount} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Balance After Recovery</label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="loanBalanceAfterRecovery" value={formData.loanBalanceAfterRecovery} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Maturity Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="maturityDate" value={formData.maturityDate ? new Date(formData.maturityDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Interest Rate (% p.a) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanInterestRate" value={formData.loanInterestRate} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Loan Overdue Rate (% p.a) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanOverdueRate" value={formData.loanOverdueRate} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        </div>
        <div className='m-4' style={{width:'70vh'}}>
        
        <h4>Interest Breackdown</h4>
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">From 1 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="from1" value={formData.from1 ? new Date(formData.from1).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">To 1 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="to1" value={formData.to1 ? new Date(formData.to1).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Rate 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="rate1" value={formData.rate1} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Value 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="value1" value={formData.value1} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Days 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="days1" value={days1} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>



        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">From 2 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="from2" value={from2 ? new Date(formData.from2).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">To 2 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="to2" value={formData.to2 ? new Date(formData.to2).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Rate 2 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="rate2" value={formData.rate2} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Value 2 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="value2" value={formData.value2} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>
        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Days 2  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="days2" value={formData.days2} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Recovery Value Date </label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="recoveryValueDate" value={formData.recoveryValueDate ? new Date(formData.recoveryValueDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Recovered Capital Amount  </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="recoveredCapitalAmount" value={formData.recoveredCapitalAmount} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
            </div>
        </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Recovered Interest Amount (LKR)  </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="recoveredInterestAmount" value={formData.recoveredInterestAmount} onChange={handleInputChange} min={0} required readOnly/>
            </div>
        </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Total Amount (LKR) </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="totalAmount" value={formData.totalAmount} onChange={handleInputChange} min={0} required readOnly/>
            </div>
        </div>
        </div>
        </div>

        
        
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Ril-settlement-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default RILSettlementupdate