import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetShipping } from '../../Service/LCShippingService';
import { GetAllCompanies } from '../../Service/CompanyService';
import jsPDF from 'jspdf';


const LCShippingTable = () => {
    const [record, setRecord] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);


    useEffect(() => {
        async function fetchRecord() {
            try {
                const response = await GetShipping();
                if (response && response.data && Array.isArray(response.data)) {
                    setRecord(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchRecord();
    }, []);

    const formatDateString = (isoDateString) => {
        const isoDate = new Date(isoDateString);
        return isoDate.toLocaleString(); 
    };

    const filterRecord = record.filter((reco) =>
    (reco.lcReferenceNo?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.amendDate?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (reco.company?.toLowerCase().includes(searchQuery.toLowerCase()) || '')

);

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await GetAllCompanies();
                if (response && response.data && Array.isArray(response.data)) {
                    setCompanyData(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchCompanies();
    }, []);


const exportToPdf = (reco) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Shipping Guaranty Advice", 10, 10);
    // const formattedTotalCharge = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(reco.totalCharge);
    // const formattedEquivalentInLkr = new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'INR' }).format(reco.equivalentInLkr);

    doc.setFontSize(12);
    doc.text(`LC Reference No: ${reco.lcReferenceNo}`, 10, 20);
    doc.text(`SG Date: ${formatDateString(reco.sgDate)}`, 10, 30);
    doc.text(`Bill Ref: ${reco.billRef}`, 10, 40);
    doc.text(`Loan Ref: ${reco.loanRef}`, 10, 50);
    doc.text(`Type of Loan: ${reco.typeofLoan}`, 10, 60);
    doc.text(`Loan Amount (LKR): ${reco.loanlAmount}`, 10, 70);
    doc.text(`Loan Period (Days): ${reco.loanPeriod}`, 10, 80);
    doc.text(`Loan Rate Per Annum (%): ${reco.loanRatePerAnnum}`, 10, 90);
    doc.text(`Penal Int Rate:${reco.penalIntRate} `, 10, 100);
    doc.text(`Loan Value Date : ${formatDateString(reco.loanValueDate)}`, 10, 110);
    doc.text(`Maturity Date : ${formatDateString(reco.maturityDate)}`, 10, 120);

    doc.text(`Company Name: ${reco.company}`,10,130)

    doc.save(`${reco.lcReferenceNo}.pdf`);
};


    return (
        <div>
            <h1>Shipping Guaranty Advice Table</h1>
            <div className="d-flex justify-content-end mt-3">
                <input type='text' placeholder='Search' className='form-control w-25 m-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                <select value={searchQuery} class="form-control w-25 m-1" aria-label="Disabled select example" onChange={(e) => setSearchQuery(e.target.value)} >
                    <option value='' selected>All</option>
                    {companyData.map((recordmap)=>(
                    <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
                    ))}
                </select>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>LC Reference No</th>
                        <th>Company</th>
                        <th>SG Date</th>
                        <th>Bill Ref</th>
                        <th>Loan Ref</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filterRecord.length > 0 ? (
                        filterRecord.map((reco) => (
                            <tr key={reco.lcReferenceNo}>
                                <td>{reco.lcReferenceNo}</td>
                                <td>{reco.company}</td>
                                <td>{formatDateString(reco.sgDate)}</td>                               
                                <td className="text-end">{reco.billRef}</td>
                                <td className="text-end">{reco.loanRef}</td>
                                <td>
                                    <button className='btn btn-success'><Link className="text-decoration-none text-white" to={`/Lc-shipping/${reco.lcReferenceNo}`}>Update</Link></button>
                                    <button className='btn btn-info m-1' onClick={() => exportToPdf(reco)}>Export</button>
                                    
                                    </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No matching record found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default LCShippingTable;
