import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Signup } from '../../Service/authService';
import Swal from 'sweetalert2';
import Logo from '../../asserts/logo.jpeg'

const initialState = {
    username: '',
    password: '',
  };
const Login = () => {

    const [formData, setFormData] = useState(initialState);
     const { username, password } = formData;
     const navigate = useNavigate();


     const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const loginUser = async(e) =>{
        e.preventDefault();
        try{
            const data = await Signup(formData);
            console.log(data);
            if (data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Login Successful!',
                    text: 'Redirecting to Dashboard...',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate('/dashboard');
                });
            }
        }catch (error) {
            console.log(error);
          }
      }


  return (
    <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className='text-center'>
                            <img style={{width:'300px', height:'300px', alignContent:'center'}} src={Logo} alt="Logo" />

                            </div>
                            <h5 className="card-title mb-4 text-center">Login</h5>

                            <form onSubmit={loginUser}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="Username" name="username" value={username} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={handleInputChange} />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                                <div>
                                    <Link className='text-decoration-none' to={'/register'}><p>Create Account</p></Link>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Login