import {BrowserRouter, Routes, Route} from "react-router-dom"
import LCOpeningAdvice from "./Pages/LCOpeningAdvice";
import Layout from "./Component/Layout/Layout";
import LCOAdvicetable from "./Pages/LCOpeningAdvice-table";
import LCOpeningAdviceupdate from "./Pages/LCOpeningAdvice-update";
import Login from "./Pages/authpages/login";
import LCAmendment from "./Pages/LCAmendment/LCAmendment";
import axios from "axios"
import LCAmendmentTable from "./Pages/LCAmendment/LCAmendment-table";
import LCAmendmentupdate from "./Pages/LCAmendment/LCAmendment-update";
import LCEnhancement from "./Pages/LCEnhancement/LCEnhancement";
import LCEnhancementTable from "./Pages/LCEnhancement/LCEnhancement-table";
import LCEnhancementupdate from "./Pages/LCEnhancement/LCEnhancement-update";
import LCDocument from "./Pages/LCDocument/LCDocument";
import LCODocumentTable from "./Pages/LCDocument/LCDocument-table";
import LCDocumentupdate from "./Pages/LCDocument/LCDDocument-update";
import LCSettlement from "./Pages/LCSettlement/LCSettlement";
import LCSettlementTable from "./Pages/LCSettlement/LCSettlement-table";
import LCSettlementupdate from "./Pages/LCSettlement/LCSettlement-update";
import LCShipping from "./Pages/LCShipping/LCShipping";
import LCShippingTable from "./Pages/LCShipping/LCShipping-table";
import LCShippingupdate from "./Pages/LCShipping/LCShipping-update";
import RILSettlement from "./Pages/RILSettlement/RILSettlement";
import RILSettlementTable from "./Pages/RILSettlement/RILSettlement-table";
import RILSettlementupdate from "./Pages/RILSettlement/RILSettlement-update";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Registerpage from "./Pages/authpages/Register";
import LoanGranting from "./Pages/LoanGranting/LoanGranting";
import LoanGrantintable from "./Pages/LoanGranting/LoanGrantin-table";
import LoanGrantingUpdate from "./Pages/LoanGranting/LoanGranting-update";


axios.defaults.withCredentials = true;
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/register" element={<Registerpage/>} />


        {/* Dashboard */}
        <Route path="/dashboard" element={<Layout><Dashboard/></Layout>} />


        {/* form1 */}
        <Route path="/Lc-Advice" element={<Layout><LCOpeningAdvice/></Layout>} />
        <Route path="/Lc-Advice-table" element={<Layout><LCOAdvicetable/></Layout>} />
        <Route path="/Lc-Advice/:refno" element={<Layout><LCOpeningAdviceupdate/></Layout>} />


        {/* form2 */}
        <Route path="/Lc-Amendment" element={<Layout><LCAmendment/></Layout>} /> 
        <Route path="/Lc-Amendment-table" element={<Layout><LCAmendmentTable/></Layout>} />
        <Route path="/Lc-Amendment/:amdno/:lcRefNo" element={<Layout><LCAmendmentupdate/></Layout>} />


        {/* form3 */}
        <Route path="/Lc-Enhancement" element={<Layout><LCEnhancement/></Layout>} /> 
        <Route path="/Lc-Enhancement-table" element={<Layout><LCEnhancementTable/></Layout>} />
        <Route path="/Lc-Enhancement/:amdno/:lcRefNo" element={<Layout><LCEnhancementupdate/></Layout>} />


        {/* form4 */}
        <Route path="/Lc-Document" element={<Layout><LCDocument/></Layout>} /> 
        <Route path="/Lc-Document-table" element={<Layout><LCODocumentTable/></Layout>} />
        <Route path="/Lc-Document/:amdno" element={<Layout><LCDocumentupdate/></Layout>} />

        {/* form5 */}
        <Route path="/Lc-Settlement" element={<Layout><LCSettlement/></Layout>} /> 
        <Route path="/Lc-Settlement-table" element={<Layout><LCSettlementTable/></Layout>} />
        <Route path="/Lc-Settlement/:amdno" element={<Layout><LCSettlementupdate/></Layout>} />

        {/* form6*/}
        <Route path="/Lc-shipping" element={<Layout><LCShipping/></Layout>} /> 
        <Route path="/Lc-shipping-table" element={<Layout><LCShippingTable/></Layout>} />
        <Route path="/Lc-shipping/:amdno" element={<Layout><LCShippingupdate/></Layout>} />


        {/* form7 */}
        <Route path="/Ril-settlement" element={<Layout><RILSettlement/></Layout>} /> 
        <Route path="/Ril-settlement-table" element={<Layout><RILSettlementTable/></Layout>} />
        <Route path="/Ril-settlement/:amdno" element={<Layout><RILSettlementupdate/></Layout>} />

        {/* form8 */}
        <Route path="/loan-granting" element={<Layout><LoanGranting/></Layout>} /> 
        <Route path="/loan-granting-table" element={<Layout><LoanGrantintable/></Layout>} />
        <Route path="/loan-granting/:amdno" element={<Layout><LoanGrantingUpdate/></Layout>} />














        

        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
