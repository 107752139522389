import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetEnhancement } from '../../Service/LCEnhancementService';
import { GetAllCompanies } from '../../Service/CompanyService';
import jsPDF from 'jspdf';


const LCEnhancementTable = () => {
    const [record, setRecord] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);


    useEffect(() => {
        async function fetchRecord() {
            try {
                const response = await GetEnhancement();
                if (response && response.data && Array.isArray(response.data)) {
                    setRecord(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchRecord();
    }, []);

    const formatDateString = (isoDateString) => {
        const isoDate = new Date(isoDateString);
        return isoDate.toLocaleString(); // Adjust the locale and options as needed
    };

    const filterRecord = record.filter((reco) =>
        reco.lcReferenceNo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        reco.amendDate?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        reco.company?.toLowerCase().includes(searchQuery.toLowerCase())

    );


    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await GetAllCompanies();
                if (response && response.data && Array.isArray(response.data)) {
                    setCompanyData(response.data);
                    console.log(response);
                } else {
                    console.error('Invalid response data:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchCompanies();
    }, []);


    const exportToPdf = (reco) => {
        const doc = new jsPDF();
    
        doc.setFontSize(16);
        doc.text("LC Enhancement Advice", 10, 10);
        // const formattedTotalCharge = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(reco.totalCharge);
        // const formattedEquivalentInLkr = new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'INR' }).format(reco.equivalentInLkr);
    
        doc.setFontSize(12);
        doc.text(`LC Reference No: ${reco.lcReferenceNo}`, 10, 20);
        doc.text(`Enhancement  Date : ${formatDateString(reco.enhancementDate)}`, 10, 30);
        doc.text(`Amendment No: ${reco.amendmentNo}`, 10, 40);
        doc.text(`For: ${reco.forv}`, 10, 50);
        doc.text(`Amend Commission: ${reco.amendCommission}`, 10, 60);
        doc.text(`Exchange Rate: ${reco.exchangeRate}`, 10, 70);
        doc.setFont("helvetica", "bold");
        doc.text(`Equivalent in LKR : ${reco.equivalentInLkr}`, 10, 80);
        doc.setFont("helvetica", "normal");
        doc.text(`Third Party Commission:${reco.thirdPartyCommission} `, 10, 90);
        doc.text(`Postage Charge: ${reco.postageCharge}`, 10, 100);
        doc.text(`Swift Charge : ${reco.swiftCharge}`, 10, 110);
        doc.text(`Other Charge : ${reco.otherCharge}`, 10, 120);
        doc.text(`Arrangement Fee : ${reco.arrangementFee}`, 10, 130);
        doc.setFont("helvetica", "bold");
        doc.text(`Total Debit Amount : ${reco.totalDebitAmount}`, 10, 140);
        doc.setFont("helvetica", "normal");


        doc.text(`Company Name: ${reco.company}`,10,150)
    
        doc.save(`${reco.lcReferenceNo}.pdf`);
    };

    return (
        <div>
            <h1>LC Enhancement Advice Table</h1>
            <div className="d-flex justify-content-end mt-3">
                <input type='text' placeholder='Search' className='form-control w-25 m-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                <select value={searchQuery} class="form-control w-25 m-1" aria-label="Disabled select example" onChange={(e) => setSearchQuery(e.target.value)} >
                    <option value='' selected>All</option>
                    {companyData.map((recordmap)=>(
                    <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
                    ))}
                </select>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Amendment No</th>
                        <th>LC Reference No</th>
                        <th>Company</th>
                        <th>Enhancement Date, </th>
                        <th>Equivalent in LKR</th>
                        <th>Total Debit Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filterRecord.length > 0 ? (
                        filterRecord.map((reco) => (
                            <tr key={reco.lcReferenceNo}>
                                <td>{reco.amendmentNo}</td>
                                <td>{reco.lcReferenceNo}</td>
                                <td>{reco.company}</td>
                                <td>{formatDateString(reco.enhancementDate)}</td>
                                <td className="text-end">{reco.equivalentInLkr}</td>
                                <td className="text-end">{reco.totalDebitAmount}</td>
                                <td>
                                    <button className='btn btn-success'><Link className="text-decoration-none text-white" to={`/Lc-Enhancement/${reco.amendmentNo}/${reco.lcReferenceNo}`}>Update</Link></button>
                                    <button className='btn btn-info m-1' onClick={() => exportToPdf(reco)}>Export</button>
                                    
                                    </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No matching record found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default LCEnhancementTable;
