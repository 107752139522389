import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { GetByIdDocument, updateDocument } from '../../Service/LCDdocumentService';
import { Link, useNavigate, useParams } from 'react-router-dom';

const LCDocumentupdate = () => {
    const { amdno } = useParams();
    const navigate = useNavigate();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [record, setRecord] = useState([]);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const [recordData, setRecordData] = useState('');
    const [formData, setFormData] = useState({
        lcReferenceNo:'',
        dcDate :'',
        billRef:'',
        billAmount:'',
        billMaturityDate:'',
        commissionRate:'',
        exchangeRate:'',
        acceptanceCommission:0,
        telexCharge:'',
        postageCharge:'',
        total:0,
    });

    const {
        lcReferenceNo,
        dcDate ,
        billRef,
        billAmount,
        billMaturityDate,
        commissionRate,
        exchangeRate,
        acceptanceCommission,
        telexCharge,
        postageCharge,
        total,
} =formData
        
    useEffect(() => {
        async function getRecords() {
            try {
                const data = await GetByIdDocument(amdno);
                setRecordData(data);
                setFormData(data);
                console.log(data);
                console.log("inside func");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getRecords();
    }, []);

    const updateFunc = async (e) => {
      calculateBtn();
        e.preventDefault();
        try {
            await updateDocument(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The Details Update successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Lc-Document/${formData.lcReferenceNo}`);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const calculateEquivalentInLKR = () => {
    //   const calEquivalentInLkr = (
    //     parseFloat(billAmount) * parseFloat(exchangeRate) * (parseFloat(commissionRate) / 100)
    //     );
    //   console.log(calEquivalentInLkr);
    //   setFormData(prev => ({ ...prev, acceptanceCommission:calEquivalentInLkr}));
    // };
  
    // const calculateTotCharge = () => {
    //   const total = parseFloat(formData.acceptanceCommission) + parseFloat(formData.telexCharge) + parseFloat(formData.postageCharge);
    //   setFormData(prevState => ({ ...prevState, total}));
    // };

    const calculateTotCharge = () => {

      const cal = (
        parseFloat(billAmount) * parseFloat(exchangeRate) * (parseFloat(commissionRate) / 100)
        );
      setFormData(prev => ({ ...prev, acceptanceCommission:cal}));
  
      const total = cal + parseFloat(formData.telexCharge) + parseFloat(formData.postageCharge);
      setFormData(prevState => ({ ...prevState, total}));
    };

    const calculateBtn = async() => {
      if (!lcReferenceNo|| !dcDate || !billRef || !billAmount || !billMaturityDate || !commissionRate || !exchangeRate || !telexCharge || !postageCharge) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }
    await calculateTotCharge();
      setIsFormFilled(true)
    };


    const clearForm = () => {
      setFormData({
        lcReferenceNo:'',
          dcDate :'',
          billRef:'',
          billAmount:'',
          billMaturityDate:'',
          commissionRate:'',
          exchangeRate:'',
          acceptanceCommission:0,
          telexCharge:'',
          postageCharge:'',
          total:0,
      });
      setIsFormFilled(false);
    };




  return (
    <div>
      <h1>LC Document Collection Update</h1>

      <form className='pt-4 form-group' onSubmit={updateFunc}>

      <div className='form-group row p-2'>
      <label className='col-sm-4 col-form-label'>Company</label>
        <div className='col-sm-7'>
        <select name='company' value={formData.company} class="form-select" aria-label="Disabled select example"  required disabled>
            <option selected>Select</option>
              <option value={formData.company}>{formData.company}</option>
          </select>
        </div>
      </div>

      <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={formData.lcReferenceNo} onChange={handleInputChange} required readOnly/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>DC Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="dcDate" value={formData.dcDate ? new Date(formData.dcDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="billRef" value={formData.billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Amount  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="billAmount" value={formData.billAmount} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Exchange Rate  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="exchangeRate" value={formData.exchangeRate } onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Maturity Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="billMaturityDate" value={formData.billMaturityDate ? new Date(formData.billMaturityDate).toLocaleDateString('en-CA') : ''} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Commission Rate % </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="commissionRate" value={formData.commissionRate} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Acceptance Commission </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="acceptanceCommission" value={parseFloat(formData.acceptanceCommission).toFixed(2)} onChange={handleInputChange} required  readOnly/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Telex Charge  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="telexCharge" value={formData.telexCharge} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Postage  </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="postageCharge" value={formData.postageCharge} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Toatal </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="total" value={parseFloat(formData.total).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>

        
        
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Lc-Document-table`}> List </Link></button>
          
        </div>
      </form>
    </div>
  )
}

export default LCDocumentupdate