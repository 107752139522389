import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { CheckAmdNo, CreateRILSettlement } from '../../Service/RILSettlemantService';
import { GetAllCompanies } from '../../Service/CompanyService';


const RILSettlement = () => {
  const [companyData, setCompanyData] = useState([]);

    
  const [formData, setFormData] =useState(
    {
        lcReferenceNo:'',
        billRef:'',
        rilDate: new Date(),
        loanRef:'',
        loanGrantedAmount:'',
        loanBalanceAfterRecovery:'',
        maturityDate:new Date(),
        loanInterestRate:'',
        loanOverdueRate:'',
        from1:new Date(),
        to1:new Date(),
        days1:'',
        rate1:'',
        value1:0,
        from2:new Date(),
        to2:new Date(),
        days2:'',
        rate2:'',
        value2:0,
        recoveryValueDate:'',
        recoveredCapitalAmount:'',
        recoveredInterestAmount:0,
        totalAmount:0,
        company:'',
        rsttype:''
    }
  );
  const [isFormFilled, setIsFormFilled] =useState(false)
  const {
    lcReferenceNo,
    billRef,
    rilDate,
    loanRef,
    loanGrantedAmount,
    loanBalanceAfterRecovery,
    maturityDate,
    loanInterestRate,
    loanOverdueRate,
    from1,
    to1,
    days1,
    rate1,
    value1,
    from2,
    to2,
    days2,
    rate2,
    value2,
    recoveryValueDate,
    recoveredCapitalAmount,
    recoveredInterestAmount,
    totalAmount,
    company,
    rsttype
  } =formData
  console.log(formData);

  const handleInputChange =(e)=>{
    const {name, value} = e.target;
    setFormData({...formData,[name]:value});
  }

  const calculateValue1 = () => {
    const calculatedValue1 = (
      (parseFloat(formData.recoveredCapitalAmount) *
      parseFloat(formData.days1) *
      parseFloat(formData.rate1))/36500.0
      
    );
    setFormData(prev => ({ ...prev, value1: calculatedValue1 }));
  };

  const calculateValue2 = () => {
    const calculatedValue2 = (
      (parseFloat(formData.recoveredCapitalAmount) *
      parseFloat(formData.days2) *
      parseFloat(formData.rate2))/36500.0
    );
    setFormData(prev => ({ ...prev, value2: calculatedValue2 }));
  };

  const calculateRecoveredInterestAmount = () => {
    const recoveredInterestAmount = parseFloat(formData.value1) + parseFloat(formData.value2);
    setFormData(prev => ({ ...prev, recoveredInterestAmount }));
  };

  // const calculateTotalAmount = () => {
  //   const totalAmount = parseFloat(formData.recoveredCapitalAmount) + parseFloat(formData.recoveredInterestAmount);
  //   setFormData(prev => ({ ...prev, totalAmount }));
  // }


  const calculateTotalAmount = () => {

    const calculatedValue1 = (
      (parseFloat(formData.recoveredCapitalAmount) *
      parseFloat(formData.days1) *
      parseFloat(formData.rate1))/36500.0
      
    );
    setFormData(prev => ({ ...prev, value1: calculatedValue1 }));


    const calculatedValue2 = (
      (parseFloat(formData.recoveredCapitalAmount) *
      parseFloat(formData.days2) *
      parseFloat(formData.rate2))/36500.0
    );
    setFormData(prev => ({ ...prev, value2: calculatedValue2 }));


    const recoveredInterestAmount = calculatedValue1 + calculatedValue2;
    setFormData(prev => ({ ...prev, recoveredInterestAmount }));

    const totalAmount = parseFloat(formData.recoveredCapitalAmount) + recoveredInterestAmount;
    setFormData(prev => ({ ...prev, totalAmount }));
  }

  
  const calculateBtn= async() =>{
    // await calculateValue1();
    // await calculateValue2();
    // await calculateRecoveredInterestAmount()

    
    
    // const isEmptyField = Object.values(formData).some(value => value === '');
    if (!company ||!lcReferenceNo || !billRef || !rilDate || !loanRef || !loanGrantedAmount || !loanBalanceAfterRecovery || !maturityDate || !loanInterestRate || !loanOverdueRate || !from1 || !to1 || !days1 || !rate1 || !from2 || !to2 || !days2 || !rate2  || !recoveryValueDate || !recoveredCapitalAmount || !rsttype ) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: 'Please fill all the input fields before calculating.',
            confirmButtonText: 'OK',
        });
        return;
    }

    await calculateTotalAmount();
    setIsFormFilled(true)
    
  }

  const clearForm = () => {
    setFormData({
      lcReferenceNo:'',
      billRef:'',
      rilDate: '',
      loanRef:'',
      loanGrantedAmount:'',
      loanBalanceAfterRecovery:'',
      maturityDate:'',
      loanInterestRate:'',
      loanOverdueRate:'',
      from1:'',
      to1:'',
      days1:'',
      rate1:'',
      value1:0,
      from2:'',
      to2:'',
      days2:'',
      rate2:'',
      value2:0,
      recoveryValueDate:'',
      recoveredCapitalAmount:'',
      recoveredInterestAmount:0,
      totalAmount:0,
      company:'',
      rsttype:''
    });
    setIsFormFilled(false);
    };
  

  const saveFunc = async(e) =>{
    e.preventDefault();

    const refExsist = await CheckAmdNo(lcReferenceNo);
    if(refExsist){
      Swal.fire({
        icon: 'warning',
        title: 'warning!',
        text: 'Ref number alrady used.',
        confirmButtonText: 'OK',
      }).then(()=>{
        return;
      })
    }

    calculateBtn();
    const data =  await CreateRILSettlement(formData);
    if(data){
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The LC Opening Advice added successfully.',
        confirmButtonText: 'OK',
      }).then(()=>{
        clearForm();
      })
    }

    
  }

  useEffect(() => {
    async function fetchCompanies() {
      try {
          const response = await GetAllCompanies();
          if (response && response.data && Array.isArray(response.data)) {
            setCompanyData(response.data);
              console.log(response);
          } else {
              console.error('Invalid response data:', response);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }
    fetchCompanies();
}, []);



  return (
    <div>
      <h1>RIL / STL Advice</h1>

      <form className='pt-4 form-group' onSubmit={saveFunc}>

      <div className='d-flex'>
          <div className='pe-2 m-4' style={{width:'70vh'}}>

          <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
          <div className='col-sm-7'>
          <select name='company' value={company} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            {companyData.map((recordmap)=>(
              <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
            ))}
          </select>
          </div>
        </div>
        {/* RIL  rsttype*/}

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>RIL/STL Type</label>
          <div className='col-sm-7'>
          <select name='rsttype' value={rsttype} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            <option value="RIL">RIL</option>
            <option value="STL">STL</option>
          </select>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref</label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="billRef" value={billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>RIL Date</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="rilDate" value={rilDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Ref  </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="loanRef" value={loanRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Granted Amount </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="loanGrantedAmount" value={loanGrantedAmount} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Balance After Recovery</label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="loanBalanceAfterRecovery" value={loanBalanceAfterRecovery} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Maturity Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="maturityDate" value={maturityDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Interest Rate <br/>(% p.a) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanInterestRate" value={loanInterestRate} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Loan Overdue Rate (% p.a) </label>
          <div className='col-sm-7'>
            <input type="number" min={0} class="form-control" name="loanOverdueRate" value={loanOverdueRate} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
        </div>

        </div>
        <div className='m-4' style={{width:'70vh'}}>
        
        <h4>Interest Breackdown</h4>
        <div className='border border-secondary m-1'>
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">From 1 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="from1" value={from1} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">To 1 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="to1" value={to1} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>

    <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Days 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="days1" value={days1} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Rate 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="rate1" value={rate1} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Value 1 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="value1" value={parseFloat(value1).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>
        </div>




        <div className='border border-secondary m-1'>
        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">From 2 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="from2" value={from2} onChange={handleInputChange} readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">To 2 (Date)</label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="to2" value={to2} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>

    <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Days 2  </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="days2" value={days2} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Rate 2 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="rate2" value={rate2} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Value 2 </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="value2" value={parseFloat(value2).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
          </div>
        </div>

        </div>
        


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Recovery Value Date </label>
          <div className='col-sm-7'>
            <input type="date" min={0} class="form-control" name="recoveryValueDate" value={recoveryValueDate} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Recovered Capital Amount  </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="recoveredCapitalAmount" value={recoveredCapitalAmount} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
            </div>
        </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Recovered Interest Amount (LKR)  </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="recoveredInterestAmount" value={parseFloat(recoveredInterestAmount).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
            </div>
        </div>

        <div className='form-group row p-2'>
            <label className='col-sm-4 col-form-label'>Total Amount (LKR) </label>
            <div className='col-sm-7'>
                <input type="number" class="form-control" name="totalAmount" value={parseFloat(totalAmount).toFixed(2)} onChange={handleInputChange} min={0} required readOnly/>
            </div>
        </div>
        </div>
        </div>

        
        
        <div>
          <button type='button' className='btn btn-success m-2' onClick={calculateBtn}>Calculate</button>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/Ril-settlement-table`}> List </Link></button>
          
        </div>
      </form>

    </div>
  )
}

export default RILSettlement