import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Layout = ({children}) => {
  return (
    <>
    <div className="d-flex">
      <Sidebar />
      <div className="flex-grow-1">
        <div className="--pad p-4">
          {children}
        </div>
      </div>
    </div>
    </>
  )
}

export default Layout