import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { GetAllCompanies } from '../../Service/CompanyService';
import { CheckAmdNo, CreateLoanGranting } from '../../Service/LoanGrantingServices';

const LoanGranting = () => {
  const [companyData, setCompanyData] = useState([]);

  const [formData, setFormData] =useState(
    {
        lcReferenceNo:'',
        sgDate:'',
        billRef:'',
        loanRef:'',
        typeofLoan:'',
        loanlAmount:'',
        loanPeriod:'',
        loanRatePerAnnum:0.00,
        penalIntRate:'',
        loanValueDate:'',
        maturityDate:'',
        company:''
    }
  );
  const [isFormFilled, setIsFormFilled] =useState(false)
  const {
        lcReferenceNo,
        sgDate,
        billRef,
        loanRef,
        typeofLoan,
        loanlAmount,
        loanPeriod,
        loanRatePerAnnum,
        penalIntRate,
        loanValueDate,
        maturityDate,
        company
  } =formData
  console.log(formData);

  const handleInputChange =(e)=>{
    const {name, value} = e.target;
    setFormData({...formData,[name]:value});
  }


  
  

  const clearForm = () => {
    setFormData({
      lcReferenceNo:'',
      sgDate:'',
      billRef:'',
      loanRef:'',
      typeofLoan:'',
      loanlAmount:'',
      loanPeriod:'',
      loanRatePerAnnum:'',
      penalIntRate:'',
      loanValueDate:'',
      maturityDate:'',
      company:''
    });
    setIsFormFilled(false);
  };
  

  const saveFunc = async(e) =>{
    e.preventDefault();

    if (!lcReferenceNo || !sgDate || !billRef || !loanRef || !typeofLoan || !loanlAmount || !loanPeriod || !loanRatePerAnnum || !penalIntRate || !loanValueDate || !maturityDate || !company) {
      Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please fill all the input fields before calculating.',
          confirmButtonText: 'OK',
      });
      return;
    }

    const refExsist = await CheckAmdNo(lcReferenceNo);
    if(refExsist){
      Swal.fire({
        icon: 'warning',
        title: 'warning!',
        text: 'Reference number alrady used.',
        confirmButtonText: 'OK',
      }).then(()=>{
        return;
      })
    }

    const data= await CreateLoanGranting(formData);
    if(data){
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The LC Loan Granting Advice added successfully.',
        confirmButtonText: 'OK',
      }).then(()=>{
        clearForm();
      })
    }

    
  }

  useEffect(() => {
    async function fetchCompanies() {
      try {
          const response = await GetAllCompanies();
          if (response && response.data && Array.isArray(response.data)) {
            setCompanyData(response.data);
              console.log(response);
          } else {
              console.error('Invalid response data:', response);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }
    fetchCompanies();
}, []);



  return (
    <div>
      <h1>Loan Granting Advice</h1>

      <form className='pt-4 form-group' onSubmit={saveFunc}>


      <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Company</label>
          <div className='col-sm-7'>
          <select name='company' value={company} class="form-select" aria-label="Disabled select example" onChange={handleInputChange} required disabled={isFormFilled}>
            <option selected>Select</option>
            {companyData.map((recordmap)=>(
              <option key={recordmap.companyid} value={recordmap.companyname}>{recordmap.companyname}</option>
            ))}
          </select>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>LC Reference No </label>
          <div className='col-sm-7'>
            <input type="text" class="form-control"  name="lcReferenceNo" value={lcReferenceNo} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>SG Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="sgDate" value={sgDate} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Bill Ref </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="billRef" value={billRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Ref  </label>
          <div className='col-sm-7'>
            <input type="text" min={0} class="form-control" name="loanRef" value={loanRef} onChange={handleInputChange} required readOnly={isFormFilled}/>
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Type of Loan  </label>
          <div className='col-sm-7'>
          <select className="form-select" name='typeofLoan' value={typeofLoan} onChange={handleInputChange} required readOnly={isFormFilled} >
            <option selected>Choose one</option>
            <option value="saab">Long Tearm</option>
            <option value="opel">Short Tearm</option>
             </select>   
            </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Amount (LKR)</label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanlAmount" value={loanlAmount} onChange={handleInputChange} required />
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Period (Days) </label>
          <div className='col-sm-7'>
            <input type="number" class="form-control" name="loanPeriod" value={loanPeriod} onChange={handleInputChange} min={0} required readOnly={isFormFilled}/>
          </div>
        </div>

       

        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Loan Rate Per Annum (%) </label>
          <div className='col-sm-7'>
            <input type="number"  class="form-control" name="loanRatePerAnnum" value={loanRatePerAnnum} onChange={handleInputChange} required  readOnly={isFormFilled}/>
          </div>
          </div>


        <div className="form-group row p-2">
          <label class="col-sm-4 col-form-label">Penal Int Rate   </label>
          <div className='col-sm-7'>
            <input type="number"  class="form-control" name="penalIntRate" value={penalIntRate} onChange={handleInputChange} readOnly={isFormFilled} />
        </div>
    </div>



        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Loan Value Date  </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="loanValueDate" value={loanValueDate} onChange={handleInputChange} min={0} required />
          </div>
        </div>

        <div className='form-group row p-2'>
          <label className='col-sm-4 col-form-label'>Maturity Date </label>
          <div className='col-sm-7'>
            <input type="date" class="form-control" name="maturityDate" value={maturityDate} onChange={handleInputChange} min={0} required />
          </div>
        </div>

        
        
        <div>
          <button type='submit' className='btn btn-success m-2'>Save</button>
          <button type='button' className='btn btn-danger m-2' onClick={clearForm}>Clear</button>
          <button className='btn btn-success m-2' ><Link className="text-decoration-none text-white" to={`/loan-granting-table`}> List </Link></button>
          
        </div>
      </form>

    </div>
  )
}

export default LoanGranting